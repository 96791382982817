var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.editUid ? "Edit" : "Create")+" article")]),(_vm.editUid)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.deleteArticle}},[_vm._v("Delete")]):_vm._e()],1),_c('div',{staticClass:"blog-form"},[_c('div',[_c('label',[_vm._v("Type")]),_c('v-select',{attrs:{"clearable":false,"options":[
          { value: 'YACHTING', label: 'Yachting News' },
          { value: 'CRUISESHIP', label: 'Cruise Ship News' },
          { value: 'MERCHANT', label: 'Merchant News' } ],"reduce":function (option) { return option.value; }},model:{value:(_vm.form.newsType),callback:function ($$v) {_vm.$set(_vm.form, "newsType", $$v)},expression:"form.newsType"}})],1),_c('div',[_c('label',[_vm._v("Image")]),_c('b-form-file',{attrs:{"accept":"image/*","placeholder":_vm.editUid ? 'Choose new image' : 'No file chosen'},on:{"change":_vm.onImageUpload}}),(_vm.imagePreview)?_c('div',{staticClass:"mt-2"},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.imagePreview,"height":"500","alt":""}})]):_vm._e()],1),_c('div',[_c('label',[_vm._v("Title")]),_c('b-form-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('div',[_c('label',[_vm._v("Content")]),_c('vue-editor',{attrs:{"useCustomImageHandler":true,"customModules":_vm.customModulesForEditor,"editorOptions":_vm.editorSettings,"editorToolbar":_vm.customToolbar},on:{"image-removed":_vm.onImageRemoved,"image-added":_vm.handleImageAdded},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('div',[_c('label',[_vm._v("Published At")]),_c('b-form-datepicker',{attrs:{"placeholder":'Select a date',"today-button":true,"reset-button":true},model:{value:(_vm.form.publishedAt),callback:function ($$v) {_vm.$set(_vm.form, "publishedAt", $$v)},expression:"form.publishedAt"}})],1),_c('div',[_c('div',[_c('label',[_vm._v("Credits")]),_c('v-select',{attrs:{"clearable":true,"options":[
            'Superyacht Times',
            'Yachting News',
            'Gcaptain',
            'BOAT International',
            'Marine Insight',
            'Maritime Executive',
            'Marine Link',
            'Marine Traffic',
            'The Maritime Standard',
            'Seatrade Maritime',
            'Dubai Maritime News',
            'Offshore Energy',
            'Cruise Industry News',
            'Cruise Hive',
            'Crew Center' ],"taggable":""},model:{value:(_vm.form.credits),callback:function ($$v) {_vm.$set(_vm.form, "credits", $$v)},expression:"form.credits"}})],1)]),_c('div',[_c('label',[_vm._v("Credits URL")]),_c('b-form-input',{model:{value:(_vm.form.refUrl),callback:function ($$v) {_vm.$set(_vm.form, "refUrl", $$v)},expression:"form.refUrl"}})],1)]),_c('div',{staticClass:"d-flex justify-content-end mt-4"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading},on:{"click":_vm.save}},[(_vm.loading)?_c('b-spinner',{staticStyle:{"margin-bottom":"2px"},attrs:{"small":""}}):_vm._e(),_vm._v(" Save ")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }