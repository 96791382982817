import axios from "@/libs/axios";
import moment from "moment";

export default {
  createArticle(data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("image", data.image);
    formData.append("credits", data.credits);
    formData.append("refUrl", data.refUrl);
    formData.append("content", data.content);
    formData.append("newsType", data.newsType);
    formData.append(
      "publishedAt",
      moment(data.publishedAt).format("YYYY-MM-DD") +
        " " +
        moment().format("HH:mm:ss")
    );

    return axios.post("/news", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateArticle(uid, data) {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("image", data.image);
    formData.append("credits", data.credits);
    formData.append("refUrl", data.refUrl);
    formData.append("content", data.content);
    formData.append("newsType", data.newsType);
    formData.append(
      "publishedAt",
      moment(data.publishedAt).format("YYYY-MM-DD") +
        " " +
        moment().format("HH:mm:ss")
    );

    return axios.put("/news/" + uid, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteArticle(uid) {
    return axios.patch(`/news/${uid}/activation`);
  },
  getArticle(uid) {
    return axios.get("/news/" + uid);
  },
  getArticles(type, page) {
    return axios.get("/news", {
      params: {
        newsType: type,
        page,
      },
    });
  },
  uploadImage(image) {
    const formData = new FormData();
    formData.append("image", image);
    return axios.post("/news/image", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteImage(image) {
    return axios.delete("/news/image", {
      data: {
        image: image,
      },
    });
  },
};
